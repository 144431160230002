// @flow

import styled from '@emotion/styled'
import { Link } from 'gatsby'
import * as React from 'react'

import { colors, fonts } from '@toggl/style'
import { text } from '@toggl/ui'

import { useAssets } from '../hooks/useAssets'

type Props = {|
  logoSrc?: string,
  logoAlt?: string,
  children: React.Node,
  speakerTitle: string,
  linkHref?: string,
  linkText?: string,
|}

export function MDXTestimonial({
  logoSrc,
  logoAlt,
  children,
  speakerTitle,
  linkHref,
  linkText = 'Read more in the case study',
}: Props) {
  const { asset } = useAssets({ asset: logoSrc })

  return (
    <Container>
      {logoSrc && (
        <LogoContainer>
          <img src={asset} alt={logoAlt} />
        </LogoContainer>
      )}
      <text.H4 color={colors.darkPurple90}>&ldquo;{children}&rdquo;</text.H4>
      <QuoteSpeaker>—&ensp;{speakerTitle}</QuoteSpeaker>
      {linkHref && (
        <ReadMoreLink>
          <span> 👉</span>
          <Link to={linkHref}>{linkText}</Link>
          <span>👈</span>
        </ReadMoreLink>
      )}
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  position: relative;
  text-align: center;
  max-width: 80ch;
  margin: 45px auto;
  padding: 0 30px;
  h4 {
    line-height: 1.5;
    margin-top: 20px;
    margin-bottom: 30px;
    letter-spacing: -0.3px;
    font-family: ${fonts.inter};
  }
  strong {
    display: inline;
    font-weight: 400;
    background-image: url(${require('assets/background-highlight.svg')
      .default});
    background-repeat: no-repeat;
    background-size: 104% auto;
    padding: 0 5px;
    margin: 0 -2px;
  }
`

const LogoContainer = styled.div`
  display: inline-block;
  img {
    width: 100%;
    max-width: 150px;
    max-height: 90px;
    object-fit: contain;
  }
`

const QuoteSpeaker = styled.div`
  ${text.paragraph4};
  color: ${colors.darkPurple70};
`

const ReadMoreLink = styled.div`
  ${text.paragraph4};
  color: ${colors.darkPurple70};
  margin-top: 8px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
`
