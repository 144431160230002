import Cookies from 'js-cookie'

const REFERRAL_COOKIE_NAME = 'rewardful.referral'

import { url } from '@toggl/auth'

export const getAccountsUrl = url.getAccountsUrl

/**
 * Returns the Rewardful referral ID from the referral cookie.
 *
 * @returns {string | null}
 */
export const getReferral = () => {
  const referral = Cookies.get(REFERRAL_COOKIE_NAME)
  if (!referral) {
    return null
  }
  try {
    const payload = JSON.parse(decodeURIComponent(referral))
    return payload.id
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * Checks if shared auth enabled for the current environment by URL.
 *
 * @param location
 * @returns {boolean}
 */
export const isSharedAuthEnabled = (location) => {
  const hostname = location?.hostname ?? window.location.hostname
  return Boolean(
    ['toggl.space', 'toggl.com'].includes(hostname) ||
      hostname.endsWith('ms.toggl.space')
  )
}

export const getTrackLoginUrl = (locationOverride) => {
  const location =
    locationOverride || (typeof window !== 'undefined' ? window.location : null)
  if (!location) {
    return '/track/login/'
  }
  const sharedAuthEnabled = isSharedAuthEnabled(location)
  if (!sharedAuthEnabled) {
    return '/track/login/'
  }
  return new URL('/track/login/', getAccountsUrl(location.href)).toString()
}

export const getTrackSignupUrl = (locationOverride) => {
  const location =
    locationOverride || (typeof window !== 'undefined' ? window.location : null)
  if (!location) {
    return '/track/signup/'
  }
  const sharedAuthEnabled = isSharedAuthEnabled(location)
  if (!sharedAuthEnabled) {
    return '/track/signup/'
  }
  return new URL('/track/signup/', getAccountsUrl(location.href)).toString()
}

export const convertYoutubeEmbedToPrivacyFriendlyUrl = (src) => {
  if (!src.includes('youtube.com')) {
    return src
  }

  try {
    const url = new URL(src)
    const privacyFriendly = new URL('https://www.youtube-nocookie.com/')
    privacyFriendly.pathname = url.pathname
    privacyFriendly.search = url.search
    privacyFriendly.searchParams.set('privacy_mode', '1')
    return privacyFriendly.href
  } catch (e) {
    console.warn(`Failed to convert youtube embed to privacy friendly url`, e)
    return src
  }
}
