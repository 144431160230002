// @flow
import styled from '@emotion/styled'
import { MDXProvider as SourceMDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import type { Mdx } from 'types'

import { colors, fonts } from '@toggl/style'
import { List, text } from '@toggl/ui'
import YoutubeEmbed from 'components/YoutubeEmbed'

import { FTECalculator } from './addons/FTECalculator'
import { MDXCallToAction } from './addons/MDXCallToAction'
import { MDXCallToActionEnterprise } from './addons/MDXCallToActionEnterprise'
import { MDXFurtherSteps } from './addons/MDXFurtherSteps'
import { MDXTestimonial } from './addons/MDXTestimonial'
import { TimeCardCalculator } from './addons/TimeCardCalculator'
import { Timer } from './addons/Timer'
import { useAssets } from './hooks/useAssets'
import { Link } from './Link'

export const MDXProvider = ({
  children,
}: {
  children?: React.Node,
}): React.Element<typeof SourceMDXProvider> => (
  <SourceMDXProvider
    components={{
      a: Link,
      h1: text.H1,
      h2: text.H2,
      h3: text.H3,
      h4: text.H4,
      h5: text.H5,
      img: Img,
      li: List.Item,
      strong: text.Strong,
      ul: List.Wrapper,
      FTECalculator,
      TimeCardCalculator,
      MDXCallToAction,
      MDXCallToActionEnterprise,
      MDXTestimonial,
      MDXFurtherSteps,
      Timer,
      iframe: Iframe,
    }}
  >
    {children}
  </SourceMDXProvider>
)

export const MDX = ({
  p,
  italic,
  children,
  theme = 'dark',
}: {
  p?: React.ComponentType<any>,
  italic?: React.ComponentType<any>,
  children: Mdx,
  theme?: 'light' | 'dark',
}): React.Element<typeof SourceMDXProvider> => {
  if (typeof children !== 'object' || children?.body == null) {
    throw new Error(
      'Unexpectedly received something that is not compiled MDX. Check the schema or page data for changes.'
    )
  }

  const Container = theme === 'light' ? LightThemeContainer : React.Fragment
  return (
    <Container>
      <SourceMDXProvider
        components={{
          p: p ?? text.P1,
          italic: italic ?? text.P1,
        }}
      >
        <MDXRenderer>{children.body}</MDXRenderer>
      </SourceMDXProvider>
    </Container>
  )
}

const LightThemeContainer = styled.div`
  h1,
  h2,
  h3,
  h4 {
    color: ${colors.darkPurple90};
  }
  h1,
  h2,
  h3 {
    em {
      color: ${colors.pink100};
      font-family: ${fonts.gtHaptikMediumRotalic};
    }
  }
  p {
    color: ${colors.darkPurple70};
    &:last-of-type {
      margin-bottom: 5px;
    }
  }
  a {
    color: ${colors.darkPurple80};
    text-decoration: underline;
    &:hover {
      color: ${colors.pink100};
    }
  }
  blockquote {
    margin: 20px 0;
    padding: 10px 25px 20px;
    border-radius: 8px;
    background: ${colors.peach80};
    p {
      margin-top: 15px;
      font-size: 95%;
    }
  }
  ol,
  ul {
    margin-bottom: 25px;
    padding-left: 20px;
    li {
      ${text.paragraph3};
      color: ${colors.darkPurple90};
      line-height: 1.6;
    }
  }
  em {
    font-style: italic;
  }
`

const Img = ({
  children,
  src,
  ...rest
}: {
  children?: React.Node,
  src?: string,
  ...
}): React.Element<'img'> => {
  const { asset } = useAssets({ asset: src })

  return (
    <img {...rest} src={asset ?? src}>
      {children}
    </img>
  )
}

const Iframe = ({
  children,
  ...props
}: {
  children?: React.Node,
  src?: string,
  ...
}) => {
  const isYoutube = props.src?.includes('youtube.com')

  // If a youtube embed is detected and marketing cookies are not accepted, replace the embed with a privacy-friendly version
  if (props.src && isYoutube) {
    return (
      <YoutubeEmbed {...props} src={props.src}>
        {children}
      </YoutubeEmbed>
    )
  }

  return <iframe {...props}>{children}</iframe>
}
