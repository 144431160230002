// @flow

import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import { colors, fonts, media } from '@toggl/style'
import { Button, text } from '@toggl/ui'

type Props = {|
  children: React.Node,
  linkText?: string,
|}

export function MDXCallToActionEnterprise({ children, linkText }: Props) {
  return (
    <Container>
      <StaticImage
        formats={['auto', 'webp', 'avif']}
        src='../../assets/icon-toggl-solution.png'
        loading='lazy'
        alt='Icons of custom reports and integration'
        width={180}
        objectFit='contain'
      />
      <TextContainer>
        {children}
        <Button.Primary as={Link} to='/track/demo-request/'>
          {linkText ? linkText : 'Talk to Sales'}
        </Button.Primary>
      </TextContainer>
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  position: relative;
  margin: 45px auto;
  border-radius: 12px;
  overflow: hidden;
  background: ${colors.peach50};
  display: none;

  ${media.mq[2]} {
    display: flex;
  }
  .gatsby-image-wrapper {
    flex: 0 0 180px;
    margin-left: 25px;
    margin-top: -30px;
    margin-bottom: -30px;
  }
  h4 {
    ${text.paragraph1};
    line-height: 1.25;
    font-family: ${fonts.inter};
    font-weight: 500;
    color: ${colors.darkPurple90};
    text-wrap: pretty;
    margin: 0 0 10px;
  }
  p {
    ${text.paragraph3};
    color: ${colors.darkPurple80};
    margin-bottom: 0;
  }
  a {
    background: ${colors.yellow100};
    color: ${colors.darkPurple100};
    text-decoration: none;
    display: inline-flex;
    margin-top: 10px;
    &:hover {
      color: ${colors.peach10};
      background: ${colors.darkPurple80};
    }
  }
`

const TextContainer = styled.div`
  padding: 30px;
`
