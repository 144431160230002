// @flow

import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import * as gtm from '@toggl/google-tag-manager'
import { colors } from '@toggl/style'

import { CookieWarning } from '../CookieWarning'

declare var process: WebProcess

export const LayoutRoot = ({
  title,
  description,
  children,
  ogImage,
  ogImageAlt,
  noSmoothScrolling = false,
}: {
  title?: string,
  description?: string,
  children: React.Node,
  ogImage?: string,
  ogImageAlt?: string,
  noSmoothScrolling?: boolean,
}): React.Element<typeof StyledLayoutRoot> => {
  React.useLayoutEffect(() => {
    gtm.activateOptimize()
    gtm.activateProfitWell()
  }, [])

  return (
    <StyledLayoutRoot>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title itemProp='name'>{title}</title>
        <meta property='og:title' content={title} />
        <meta
          name='description'
          property='og:description'
          content={
            description ||
            'When time tracking is this easy, you can get more done. Toggl Track data brings clarity around profitability and helps you make better business decisions.'
          }
        />
        <meta
          property='og:image'
          content={ogImage || require('assets/toggl-track-main.jpg').default}
        />
        {ogImageAlt && <meta property='og:image:alt' content={ogImageAlt} />}
        <meta name='twitter:card' content='summary_large_image' />
        <link
          rel='preconnect'
          href={new URL(process.env.GATSBY_PUBLIC_PATH).origin}
        />
        <link
          rel='preconnect'
          href={new URL(process.env.GATSBY_PUBLIC_PATH).origin}
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={
            require('@toggl/fonts/gt-haptik-medium-500.latin.woff2').default
          }
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={
            require('@toggl/fonts/gt-haptik-medium-rotalic-500.latin.woff2')
              .default
          }
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={require('@toggl/fonts/inter-400.latin.woff2').default}
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={require('@toggl/fonts/inter-500.latin.woff2').default}
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link rel='dns-prefetch' href='https://www.googletagmanager.com' />
        <link rel='dns-prefetch' href='https://www.googleadservices.com' />
        <link rel='dns-prefetch' href='https://googleads.g.doubleclick.net' />
        <link rel='dns-prefetch' href='https://stats.g.doubleclick.net' />
        <link rel='dns-prefetch' href='https://bat.bing.com' />
        <link rel='dns-prefetch' href='https://facebook.com' />
        <link rel='dns-prefetch' href='https://connect.facebook.net' />
        <link rel='dns-prefetch' href='https://px.ads.linkedin.com' />
        <link rel='dns-prefetch' href='https://snap.licdn.com' />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={require('./assets/favicon/apple-touch-icon.png').default}
        />
        <link
          rel='icon'
          href={require('./assets/favicon/favicon.svg').default}
          type='image/svg+xml'
        ></link>
        <link
          rel='icon'
          href={require('./assets/favicon/favicon.ico').default}
          sizes='any'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='48x48'
          href={require('./assets/favicon/favicon-48x48.png').default}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={require('./assets/favicon/favicon-32x32.png').default}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={require('./assets/favicon/favicon-16x16.png').default}
        />
        <script data-cookieconsent='statistics'>
          {`!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.crossOrigin="anonymous",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted captureException loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
        
        // Ensure posthog only get's loaded if the user consented to it.
        let postHog = false
        if (window?.Cookiebot?.consent?.statistics) {
          posthog.init('${process.env.GATSBY_POSTHOG_ID}',{
            api_host:'${process.env.GATSBY_POSTHOG_HOST}',
            autocapture: {
              css_selector_allowlist: ['[data-heap-id]'],
              element_allowlist: ['button', 'a'],
            },
            disable_session_recording: true,
            session_recording: {
              maskAllInputs: true,
              maskTextSelector: '.posthog-hide, [data-posthog-hide="true"]',
            },
          })
          postHog = true
        }
        window.addEventListener('CookiebotOnAccept', function() {
          if (!postHog && window?.Cookiebot?.consent?.statistics) {
            posthog.init('${process.env.GATSBY_POSTHOG_ID}',{
              api_host:'${process.env.GATSBY_POSTHOG_HOST}',
              autocapture: {
                css_selector_allowlist: ['[data-heap-id]'],
                element_allowlist: ['button', 'a'],
              },
              disable_session_recording: true,
              session_recording: {
                maskAllInputs: true,
                maskTextSelector: '.posthog-hide, [data-posthog-hide="true"]',
              },
            })
            postHog = true
          }
        }, false)
        `}
        </script>
      </Helmet>

      {noSmoothScrolling && (
        <Global
          styles={css`
            html {
              scroll-behavior: auto;
            }
          `}
        />
      )}
      {children}
      {process.env.GATSBY_NEW_COOKIE_CONSENT_ENABLED !== 'true' && (
        <CookieWarning />
      )}
    </StyledLayoutRoot>
  )
}

const StyledLayoutRoot = styled.div<{ ... }>(`
  background-color: ${colors.darkPurple100};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`)
