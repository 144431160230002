// @flow

import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'
import { text } from '@toggl/ui'

type Props = {|
  title?: string,
  children: React.Node,
|}

export function MDXFurtherSteps({ title, children }: Props) {
  return (
    <Container>
      {title && <Title>{title}:</Title>}
      <Content>{children}</Content>
    </Container>
  )
}

const Container: React.ComponentType<React.ElementConfig<'div'>> = styled.div`
  margin: 30px 0;
  ${media.mq[1]} {
    margin-top: 45px;
  }
`

const Title = styled.div`
  ${text.paragraph4};
  text-transform: uppercase;
  font-weight: bold;
  color: ${colors.darkPurple80};
  margin-bottom: 15px;
`

const Content = styled.div`
  p {
    margin: 15px 0 !important;
    position: relative;
    line-height: 1.5;
    border-radius: 12px;
    overflow: hidden;
    background: ${colors.peach50};
    padding: 15px 25px 15px 80px;

    &:before {
      content: attr(data-emoji);
      font-size: 1.8rem;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: ${colors.peach100};
      padding: 0 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  a {
    color: ${colors.darkPurple80};
    text-decoration: underline;
    &:hover {
      ${colors.darkPurple100};
    }
  }
`
