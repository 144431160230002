// @flow

export const getAuthUrl = (fallback: string) => {
  const url = new URL(
    typeof window === 'undefined' ? fallback : window.location
  )

  if (
    /toggl\.(com|space)$/.test(url.hostname) &&
    !/public-web/.test(url.hostname)
  ) {
    return new URL('/auth', `${url.protocol}//track.${url.host}`).toString()
  }

  const defaultUrl = new URL('https://track.toggl.space/auth')
  defaultUrl.searchParams.set('trustedOrigin', url.origin)
  return defaultUrl.toString()
}

/**
 * Returns the URL of the accounts service for the current environment. This
 * works independently of the GATSBY_ACCOUNTS_URL environment variable to
 * satisfy the requirements of custom staging environments.
 *
 * @param forUrl - The URL to use for the accounts service. If not provided, the
 *   current window.location is used.
 * @returns {string}
 */

export const getAccountsUrl = (forUrl?: string): string => {
  const pageUrl =
    forUrl || (typeof window !== 'undefined' ? window.location.href : null)
  if (!pageUrl) {
    return process.env.GATSBY_ACCOUNTS_URL ?? 'https://accounts.toggl.space'
  }
  const { hostname } = new URL(pageUrl)
  if (['toggl.space', 'toggl.com'].includes(hostname)) {
    return `https://accounts.${hostname}`
  }
  const stagingEnv = hostname.match(/^((\S)+)\.ms.toggl\.space$/)?.[1]
  if (stagingEnv) {
    return `https://accounts.${stagingEnv}.ms.toggl.space`
  }
  return process.env.GATSBY_ACCOUNTS_URL ?? 'https://accounts.toggl.space'
}
