// @flow
import Cookies from 'js-cookie'

declare var process: WebProcess

import { getAccountsUrl } from './url'

const TRACK_SESSION_COOKIE_NAME = '__track_session'
const USER_ID_COOKIE_NAME = 'track_userId'

export const syncLocalSessionWithAccounts = async () => {
  const checkUrl = getAccountsUrl() + '/api/sessions'
  const hasSession = await fetch(checkUrl, {
    credentials: 'include',
  })
    .then((response) => response.ok)
    .catch(() => false)
  if (
    !hasSession &&
    (Cookies.get(TRACK_SESSION_COOKIE_NAME) || Cookies.get(USER_ID_COOKIE_NAME))
  ) {
    destroy()
  }
}

export const check = (): boolean => !!Cookies.get(TRACK_SESSION_COOKIE_NAME)

export const create = (userId: number) => {
  const domain = getDomain()

  Cookies.set(TRACK_SESSION_COOKIE_NAME, Math.floor(Date.now() / 1000), {
    expires: 365,
    domain,
  })

  Cookies.set(USER_ID_COOKIE_NAME, userId, { expires: 365, domain })
}

export const destroy = () => {
  const domain = getDomain()

  Cookies.remove(TRACK_SESSION_COOKIE_NAME, { domain })
  Cookies.remove(USER_ID_COOKIE_NAME, { domain })
}

export const getUserId = (): string | void => Cookies.get(USER_ID_COOKIE_NAME)

// FIXME: This isn't foolproof, i.e. won't work on torulukksepad.io.
const getDomain = () =>
  window.location.hostname.includes('space') ? '.toggl.space' : '.toggl.com'
